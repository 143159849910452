import { useCallback } from 'react'
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip'
import { theme } from 'twin.macro'

const tooltipStyles = {
  ...defaultStyles,
  minWidth: theme`spacing.52`,
  maxWidth: theme`spacing.72`,
  width: theme`width.auto`,
  padding: theme`spacing.4`,
  backgroundColor: theme`colors.white`,
  color: theme`colors.grey.2`,
  borderRadius: theme`borderRadius.md`,
  boxShadow: theme`boxShadow.lg`,
  zIndex: '999',
}

export default function useSVGTooltip() {
  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal(
    {
      detectBounds: true,
    }
  )

  const {
    hideTooltip,
    showTooltip,
    tooltipOpen,
    tooltipLeft = 0,
    tooltipTop = 0,
    tooltipData = '',
  } = useTooltip()

  const handlePointer = useCallback(
    (event, data) => {
      // coordinates should be relative to the container in which Tooltip is rendered
      const containerX = (event.clientX ?? 0) - containerBounds.left
      const containerY = (event.clientY ?? 0) - containerBounds.top

      showTooltip({
        tooltipLeft: containerX,
        tooltipTop: containerY,
        tooltipData: data,
      })
    },
    [showTooltip, containerBounds]
  )

  return {
    containerRef,
    handlePointer,
    TooltipInPortal,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    tooltipData,
    tooltipStyles,
  }
}
